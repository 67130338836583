.global__stats {
  text-align: center;
  width: 94%;
  align-items: center;
  display: flex;
  border-radius: 5px;
  margin: 0 auto;
  justify-content: space-around;
  &-div {
    &:not(:first-child) {
      margin-left: 20px;
    }
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 20px 10px;
    font-weight: 700;
    letter-spacing: 2px;
    box-shadow: 0px 0px 8px grey;
    border-radius: 5px;
    width: 100%;
  }
  &-A {
    &1 {
      color: #eb9053;
      font-size: 30px;
    }
    &2 {
      color: #38a169;
      font-size: 30px;
    }
    &3 {
      color: #e53e3e;
      font-size: 30px;
    }
  }
  &-B {
    color: #718096;
    font-size: 30px;
  }
  &-C {
    &1 {
      color: #f59773;
      font-size: 18px;
    }
    &2 {
      color: #38a169;
      font-size: 18px;
    }
    &3 {
      color: #e53e3e;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 580px) {
  .global__stats {
    flex-direction: column;
    width: 85%;
    &-div {
      &:not(:first-child) {
        margin-left: 0;
      }
      margin-top: 20px;
      margin-bottom: 0;
      padding: 10px 10px;
      letter-spacing: 3px;
    }
    &-A {
      &1,
      &2,
      &3 {
        font-size: 25px;
      }
    }
    &-B {
      font-size: 25px;
    }
    &-C {
      &1,
      &2,
      &3 {
        font-size: 15px;
      }
    }
  }
}
