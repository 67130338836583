.global__stats {
  &-chart {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &-1-of-2 {
      width: 45%;
      height: 300px;
      padding: 10px;
      &:first-child {
        margin-right: 20px;
      }
      margin: 20px 0;
      box-shadow: 0px 0px 8px grey;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 750px) {
  .global__stats {
    &-chart {
      flex-direction: column;
      &-1-of-2 {
        width: 85%;
        height: 300px;
        margin-top: 0px;
        &:first-child {
          margin-right: 0;
          margin-top: 20px;
        }
      }
    }
  }
}
