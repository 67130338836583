.header {
  text-align: center;
  margin: 20px 25px 25px;
  height: 70px;

  display: flex;
  justify-content: space-between;

  position: relative;

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 70px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.options {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;

  .option {
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 15px;
    transition: all 0.2s;
    cursor: pointer;
    border: none;
    letter-spacing: 1px;
    outline: none;
    font-weight: 400;
    font-size: 17px;

    &,
    &:visited,
    &:link {
      color: #f7fcfe;
      outline: none;
    }
    &:hover {
      transform: translateY(-1px);

      color: #f58282;
      font-weight: 800;
    }
    &:active {
      transform: translateY(0);
    }
  }
  .icon {
    height: 18px;
    padding: 0px 7px;
    padding-left: 0;
  }
}
.toggle {
  width: 100%;
  height: 50px;

  color: white;
  display: none;
  .fa-bars {
    position: absolute;
    font-size: 40px;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .toggle {
    display: block;
  }
  .margin-big {
    margin-bottom: 120px;
  }
  .options {
    width: 100%;
    flex-direction: column;
    display: none;
  }
  ul {
    width: 100%;
    padding-top: 15px;
  }
  ul li {
    flex-direction: column;
    padding: 8px 10px;
  }
  .active {
    display: block;
  }
}
