.login-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  height: 69vh;
  .login {
    &-title {
      text-align: center;
      color: white;
      letter-spacing: 1.5px;
      font-size: 30px;
      margin-bottom: 20px;
    }
    &-input {
      width: 220px;
      color: grey;
      padding: 12px;
      margin: 8px auto;
      border-radius: 5px;
      outline: none;
      border: 1px solid grey;
      box-shadow: 0.5px 0.5px 10px rgba(grey, 0.5);
      font-size: 15px;
    }
    &-button {
      padding: 10px 8px;
      margin: 15px auto;
      width: 160px;
      border-radius: 5px;
      outline: none;
      border: 1px solid grey;
      background-color: transparent;
      color: white;
      transition: all 0.5s;

      cursor: pointer;
      &:hover {
        background-color: white;
        color: black;
        box-shadow: 0.5px 0.5px 15px rgba(grey, 0.5);
        transform: translateY(-5px);
        box-shadow: 0.5px 0.5px 5px rgba(#000, 0.5);
      }
      &:active {
        transform: translateY(4px);
      }
    }
  }
}
