.title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-title {
    text-align: center;
    width: 50%;
    height: 450px;
    letter-spacing: 1.5px;

    .title {
      padding-top: 40px;
      color: #f79595;
      font-size: 80px;
      padding-bottom: 10px;
      background-color: #161625;
      &__sub {
        color: white;
        background-color: #161625;
        font-size: 40px;
        letter-spacing: 2.5px;
        padding-bottom: 10px;
        display: block;
      }
    }
  }

  &-image {
    position: relative;

    width: 49%;

    overflow: hidden;

    .logo,
    .logo > * {
      position: absolute;
      left: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      object-fit: fill;
    }
  }
}

.title__button {
  font-size: 22px;
  letter-spacing: 1.3px;
  margin: 50px 30px 0;
  padding: 8px 20px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  border: 1px solid #f79595;
  transition: all 0.4s;
  background-color: #161625;
  color: white;
  font-weight: 500;
  box-shadow: 1px 0px 8px rgba(#fff, 0.5);
  &:hover {
    background-color: #161625;
    color: #f79595;
    box-shadow: 1px 0px 10px rgba(#f79595, 0.5);
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 1px 0px 8px rgba(#f79595, 0.9);
    transform: translateY(2.5px);
  }
}
.gutter {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid grey;
}

.info-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding-bottom: 20px;

  flex-wrap: wrap;

  &__1 {
    width: 50%;

    .symptoms {
      height: 150px;
      width: 150px;
      background-color: transparent;
      clip-path: circle(40%);
    }
    &-title {
      margin-top: 50px;
      margin-bottom: 30px;
      color: white;
      text-align: center;
      font-size: 40px;
    }
  }
}

.symptoms-container-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.symptoms-container {
  display: inline-block;
  color: white;
  text-align: center;
  font-size: 20px;
  margin: 0 20px;
}

.title-color {
  display: inline-block;
  color: #f79595;
}

.split {
  display: block;
  padding: 1px 0;
}

// STATS

.world-stats {
  &-container {
    display: flex;
    flex-direction: row;
    height: 300px;
    margin: 20px;
  }
  &-image {
    width: 50%;
    height: 100%;
    &__img {
      width: 100%;
      height: 100%;
    }
  }
  &-content {
    width: 50%;
    height: 100%;
    text-align: center;
    &__text {
      margin: 70px 0 40px;
      color: white;
      font-size: 40px;
    }
    &__btn {
      &,
      &:active &:hover {
        outline: none;
      }
      cursor: pointer;
      transition: all 0.8s;
      font-size: 24px;
      border-radius: 10px;
      padding: 10px 20px;
      background-color: #161625;
      color: white;
      border: 1px solid white;
      box-shadow: 1px 0px 8px rgba(#fff, 0.5);
      &:hover {
        color: #161625;
        background-color: white;
      }
      &:active {
        transform: translateY(5px);
        box-shadow: 2px 0px 10px rgba(#fff, 0.5);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .info-container {
    flex-direction: column;

    &__1 {
      width: 100%;
      justify-content: space-between;
    }
  }
  .symptoms-container-wrap {
    justify-content: space-around;
    margin: 0 100px;
  }
}

@media screen and (max-width: 830px) {
  .title-container {
    flex-direction: column;
    &-title {
      width: 100%;
    }
  }
}

@media screen and (max-width: 540px) {
  .symptoms-container {
    display: inline-block;
    color: white;
    text-align: center;
    font-size: 18px;
    margin: 0 1.5px;
  }
  .info-container {
    &__1 {
      .symptoms {
        height: 120px;
        width: 120px;
        background-color: transparent;
        clip-path: circle(40%);
      }
    }
  }
  .title-container {
    &-title {
      height: 320px;
      letter-spacing: 0;
      .title {
        padding-top: 22px;
        font-size: 70px;
        padding-bottom: 10px;
        &__sub {
          font-size: 37px;
          letter-spacing: 1.5px;
          padding-bottom: 4px;
        }
      }
      .title__button {
        font-size: 20px;
        margin: 30px 15px 0;
      }
    }
  }

  .world-stats {
    &-container {
      height: 220px;
      margin: 10px;
    }
    &-image {
      width: 45%;
    }
    &-content {
      width: 55%;

      &__text {
        font-size: 30px;
        margin: 30px auto 20px;
      }
      &__btn {
        font-size: 20px;
      }
    }
  }
}
