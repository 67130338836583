.footer {
  margin-top: 100px;

  height: 100px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: 30px;
  &-social {
    margin: auto 0;
    padding-left: 40px;
    .fab {
      padding: 10px;
      color: #161625;
      &:hover {
        color: #ff5252;
      }
    }
  }
  &-brand {
    text-align: center;
    margin: auto 0;
    padding-right: 50px;
    color: #fb7373;
    font-weight: 600;

    .dialogue {
      font-size: 20px;
      color: black;
    }
  }
}
@media screen and (max-width: 480px) {
  .footer {
    margin-top: 30px;
    font-size: 25px;
    &-social {
      padding-left: 1px;
      .fab {
        padding: 8px;
      }
    }
    &-brand {
      padding-right: 5px;
      .dialogue {
        font-size: 15px;
      }
    }
  }
}
