.table-title {
  text-align: center;
  color: white;
  font-size: 38px;
  margin: 40px auto;
}
.searchbox {
  outline: none;
  font-size: 15px;
  margin-left: 35px;
  margin-bottom: 10px;
  padding: 10px 0px 10px 12px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid grey;
  color: grey;
  transition: all 0.4s;

  &:focus {
    box-shadow: 0px 0px 6px rgba(white, 0.5);
  }
}
.search-container {
  position: relative;
  .fa-search {
    color: grey;
    position: absolute;
    left: 195px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
.page-head {
  color: white;
  font-size: 40px;
  margin-left: 42px;
  &-split {
    font-size: 45px;
    color: #fb7373;
  }
}

.table-countries {
  &-country {
    color: #bdbdbd;
    opacity: 0.95;
    background-color: rgb(30, 30, 48);
  }
  &-confirmed {
    color: #eb9053;
  }
  &-deaths {
    color: #e53e3e;
  }
  &-recovered {
    color: #4caf50;
  }
}

table {
  width: 100%;

  border-collapse: separate;
  border-spacing: 15px;
  font-size: 18px;
  letter-spacing: 1.2px;
  th {
    padding: 10px 0 15px;
    font-weight: 700;
    background-color: rgb(30, 30, 48);
    color: #bdbdbd;
    margin: 10px;

    border-radius: 3px;
    z-index: 1;
  }
  td {
    padding: 12px 0 10px;

    box-shadow: 0px 0px 1px grey;
    border-radius: 3px;
  }
}

.table-container {
  margin: 10px auto 50px;
  box-shadow: 0px 0px 15px grey;
  border-radius: 5px;
  width: 95%;
  height: 600px;
  text-align: center;
  overflow: scroll;
}
thead tr:first-child th {
  position: sticky;
  top: 0;
}

@media screen and (max-width: 480px) {
  .page-head {
    font-size: 35px;

    &-split {
      font-size: 40px;
    }
  }
}
@media screen and (max-width: 500px) {
  .table-container {
    width: 90%;
  }
  .searchbox {
    margin-left: 25px;
    padding: 10px 0px 10px 14px;
  }
}
.progressbar {
  width: 320px;
}
