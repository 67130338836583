.india-bar {
  height: 600px;

  &-wrapper {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    height: 400px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px grey;
    border-radius: 5px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 500px) {
  .india-bar {
    &-wrapper {
      width: 90%;
    }
  }
}
