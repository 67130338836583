html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body {
  font-family: 'Lato', sans-serif;
  background-color: #161625;
}
::-webkit-scrollbar {
  width: 3.5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #161625;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10%;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(180, 168, 168);
}
